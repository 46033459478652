import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col, Image } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { each } from 'lodash';

import { search as searchApps } from './registered_app/api';
import { search as searchAccounts } from './accounts/api';
import { create, update } from './site_templates/api';

export class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.previewFileInput = React.createRef();
    this.importFileInput = React.createRef();
  }

  static propTypes = {
    siteTemplate: PropTypes.shape({
      account: PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string
      }),
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      previewImage: PropTypes.string,
      public: PropTypes.bool,
      registeredApp: PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string
      }),
      templateType: PropTypes.string,
      isDefaultForApp: PropTypes.bool
    }).isRequired
  }

  state = {
    siteTemplate: this.props.siteTemplate,
    submitting: false
  }

  isNewTemplate = () => !this.state.siteTemplate.id

  searchRegisteredApps = (inputValue) => {
    return searchApps(inputValue).then((apps) => {
      return apps.map(app => ({ id: app.id, label: app.name }));
    });
  };

  searchAccounts = (inputValue) => {
    return searchAccounts(this.state.siteTemplate.registeredApp.id, inputValue).then((accounts) => {
      return accounts.map(account => ({ id: account.id, label: account.name }));
    });
  }

  handleUpdated = () => {
    this.setState({ submitting: false, saved: true });

    setTimeout(() => {
      this.setState({ saved: false });
    }, 3000);
  }

  handleSubmit = () => {
    this.setState({ submitting: true });

    const { siteTemplate } = this.state;

    const data = {
      id: siteTemplate.id,
      name: siteTemplate.name,
      description: siteTemplate.description,
      registered_app_id: siteTemplate.registeredApp.id,
      account_id: (siteTemplate.account || {}).id || '',
      public: siteTemplate.public,
      template_type: siteTemplate.templateType || ''
    };
    if (this.previewFileInput.current.files[0]) data.preview_image = this.previewFileInput.current.files[0];

    const formData = new FormData();
    each(data, (value, key) => formData.append(`site_template[${key}]`, value));

    if (this.isNewTemplate() && this.importFileInput.current.files[0]) {
      formData.append('import_file', this.importFileInput.current.files[0]);
    }

    if (!this.isNewTemplate()) {
      update(siteTemplate.id, formData).then(this.handleUpdated);
    } else {
      create(formData).then(data => window.location.href = data.path);
    }

  }

  handleInputChange = (event) => {
    const { value, name, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    this.setState((prevState) => {
      return { siteTemplate: { ...prevState.siteTemplate, [name]: newValue } };
    });
  }

  handlePreviewImageChange = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState((prevState) => {
          return { siteTemplate: { ...prevState.siteTemplate, previewImage: e.target.result } };
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleRegisteredAppChange = (app) => {
    this.setState((prevState) => {
      return { siteTemplate: { ...prevState.siteTemplate, registeredApp: app, account: null } };
    });
  }

  handleAccountChange = (account) => {
    this.setState((prevState) => {
      return { siteTemplate: { ...prevState.siteTemplate, account: account } };
    });
  }

  valid() {
    const { name, registeredApp } = this.state.siteTemplate;

    return !!name && !!registeredApp;
  }

  renderAppAndAccountSelectors() {
    const isDefaultForApp = this.state.siteTemplate.isDefaultForApp;
    let defaultMessage;

    if (isDefaultForApp) {
      defaultMessage = (
        <Form.Text className="text-muted pb-4">
          Note: App &amp; Account can&apos;t be changed because template is the registered app&apos;s default
        </Form.Text>
      );
    }

    return (
      <React.Fragment>
        {this.renderAppSelect(isDefaultForApp)}
        {this.renderAccountSelect(isDefaultForApp)}
        {defaultMessage}
      </React.Fragment>
    );
  }

  renderAppSelect(isDefaultForApp) {
    const { siteTemplate } = this.state;
    let selectElem;

    if (isDefaultForApp) {
      selectElem = (
        <Form.Text>{siteTemplate.registeredApp.label}</Form.Text>
      );
    } else {
      selectElem = (
        <AsyncSelect
          name="registeredAppId"
          loadOptions={this.searchRegisteredApps}
          value={siteTemplate.registeredApp || { id: null, label: ''}}
          isClearable={true}
          onChange={this.handleRegisteredAppChange} />
      );
    }

    return (
      <Form.Group>
        <Form.Label>App</Form.Label>
        {selectElem}
      </Form.Group>
    );
  }

  renderAccountSelect(isDefaultForApp) {
    const { siteTemplate } = this.state;
    const account = siteTemplate.account;
    let selectElem;

    if (isDefaultForApp) {
      selectElem = (
        <Form.Text>{account ? (account.label || '(unset)') : '(unset)'}</Form.Text>
      );
    } else {
      selectElem = (
        <React.Fragment>
          <AsyncSelect
            name="accountId"
            value={siteTemplate.account || { id: null, label: ''}}
            loadOptions={this.searchAccounts}
            isClearable={true}
            onChange={this.handleAccountChange}
          />
          <Form.Text className="text-muted">
            Leave blank to make available to all accounts
          </Form.Text>
        </React.Fragment>
      );
    }

    if (siteTemplate.registeredApp && siteTemplate.registeredApp.id) {
      return (
        <Form.Group>
          <Form.Label>Account</Form.Label>
          {selectElem}
        </Form.Group>
      );
    }
  }

  render() {
    return (
      <Form action={this.props.path} method={this.props.method}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control name="name" value={this.state.siteTemplate.name || ''} onChange={this.handleInputChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={this.state.siteTemplate.description || ''}
            onChange={this.handleInputChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Template Type</Form.Label>
          <Form.Control
            name="templateType"
            value={this.state.siteTemplate.templateType || ''}
            onChange={this.handleInputChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Public"
            checked={this.state.siteTemplate.public}
            name="public"
            onChange={this.handleInputChange}
          />
        </Form.Group>

        <Form.Group>
          <Row>
            <Col>
              <Form.File
                accept="image/jpeg,image/png,image/svg+xml"
                label="Preview Image"
                name="preview_image"
                ref={this.previewFileInput}
                onChange={this.handlePreviewImageChange}
              />
            </Col>
            <Col>
              {this.state.siteTemplate.previewImage && (
                <Image src={this.state.siteTemplate.previewImage} fluid />
              )}
            </Col>
          </Row>
        </Form.Group>

        {this.isNewTemplate() && (
          <Form.Group>
            <Form.File
              accept="application/json"
              label="Import Site Template JSON"
              name="import_file"
              ref={this.importFileInput}
            />
            <Form.Text className="text-muted">
              This will override the name and description
            </Form.Text>
          </Form.Group>
        )}

        {this.renderAppAndAccountSelectors()}

        <Button variant="primary" disabled={!this.valid() || this.state.submitting} onClick={this.handleSubmit}>
          Submit
        </Button>

        {this.state.saved &&
          <span className="ml-3 text-success">Saved</span>
        }
      </Form>
    );
  }
}

export default hot(TemplateForm);
